import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {postRefreshTokenUrl} from "../../constants/api/soft-user/endpoints";

export const useRefreshToken = ({userIsSoft}: {userIsSoft?: boolean}) => {

  async function postData({refreshToken}: {refreshToken: string}) {
    return axios.post(backendBaseUrl + postRefreshTokenUrl(userIsSoft), {
      refresh: refreshToken
    })
  }

  return useMutation({
    mutationKey: ['refresh-token'],
    mutationFn: postData,
  });
}

import {PlarCardType} from "../PlarCard";
import {ChicletTypes} from "../../../../../constants/ui/types/ChicletTypes";
import SkillChiclet from "../../../Chiclets/SkillChiclet/SkillChiclet";
import ProgressChiclet from "../../../Chiclets/ProgressChiclet/ProgressChiclet";
import {CourseCardProps} from "./index";
import AddToCartIcon from '../../../../../assets/icons/UI/icons/add-to-cart.svg';
import RemoveFromCartIcon from '../../../../../assets/icons/UI/icons/remove-from-cart.svg';
import PaymentChiclet from "../../../Chiclets/PaymentChiclet/PaymentChiclet";

const CourseCardStatus = (props: CourseCardProps) => {
  const {
    type = PlarCardType.Not_started,
    chicletText,
    statusText,
    match,
    cpl_eligible,
    payment_status,
    changePaymentStatus
  } = props;

  const cardTypeToChicletType = {
    [PlarCardType.Not_started]: ChicletTypes.Not_started,
    [PlarCardType.Accepted]: ChicletTypes.Completed,
    [PlarCardType.Completed]: ChicletTypes.Completed,
    [PlarCardType.Rejected]: ChicletTypes.Overdue,
    [PlarCardType.In_progress]: ChicletTypes.In_progress,
  }

  const renderPaymentStatus = () => {
    if (cpl_eligible) {
      switch (payment_status) {
        case 'selected-for-payment': {
          return <span className={'course-card-status-payment'} onClick={changePaymentStatus}>
            <img src={RemoveFromCartIcon} alt={'remove-from-cart'}/>
            <span className={'course-card-status-payment-text'}>
              Remove from cart
            </span>
          </span>
        }
        case 'not-selected-for-payment': {
          return <span className={'course-card-status-payment'} onClick={changePaymentStatus}>
            <img src={AddToCartIcon} alt={'add-to-cart'}/>
            <span className={'course-card-status-payment-text'}>
              Add to cart
            </span>
          </span>
        }
        case 'paid': {
          return <PaymentChiclet text={'Paid'}/>
        }
      }
    }
  }

  return <div className={`course-card-status course-card-status-${type.replaceAll(' ', '_')}`}>
    <div>
      {
        renderPaymentStatus()
      }
      {
        match
          ? <SkillChiclet text={match}/>
          : null
      }
      {
        chicletText
          ? <ProgressChiclet type={cardTypeToChicletType[type]} text={chicletText}/>
          : null
      }
    </div>
    {(statusText && statusText.length > 0)
      ? statusText.map((item: string, index: number) =>
        <div className={'course-card-status-text'} key={index}>
          {item}
        </div>)
      : null
    }
  </div>
}

export default CourseCardStatus;
import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../../hooks/Context/UserContext";
import {PermissionType} from "../../../../constants/types/PermissionType";
import MenuLayout from "../../../Menu/MenuLayout";
import {
  CplApplicantMenuOptions,
  CplApplicantMarketplaceMenuOptions,
  CplApplicantOnlyMarketplaceMenuOptions
} from "../../../../constants/menuOptions/CplApplicantMenuOptions";
import {useQueryUserInfo} from "../../../../api/admin/useQueryUserInfo";
import {PortfolioContext, PortfolioStatusTypes} from "../../../../hooks/Context/PortfolioContext";
import Loading from "../../../UI/Loading/Loading";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {isMarketplaceUrl} from "../../../../lib/envFunctions/isMarketplaceUrl";
import SoftUserHeader from "../../../Headers/SoftUserHeader";
import Footer from "../../../Footer";

const CplApplicantLayout = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [user] = useContext(UserContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const {refetch} = useQueryUserInfo({});
  const [showLoading, setShowLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!user.isSoft) {
      if ((user.user_id || user.id) && (showLoading || (!portfolio.id && user.permission_groups?.includes(PermissionType.cpl_applicant)))) {
        refetch().then(response => {
          if (!response.isError || response?.failureReason?.message !== 'no access token') {
            if (response && response.isError) {
              throw new Error('portfolio not valid');
            } else {
              setShowLoading(false);
              if (response.data && !portfolio.start_over) {
                setPortfolio({
                  ...response.data,
                  start_over: portfolio.start_over ? portfolio.start_over : response.data.start_over,
                });
              }
            }
          }
        }).catch(() => {
          setPortfolio({
            status: PortfolioStatusTypes.not_created,
            id: 'unset',
          });
          setShowLoading(false);
        });
      }
    } else {
      if (!portfolio.id || portfolio.id === 'unset') {
        let savedId = localStorage.getItem('portfolio_id');
        let marketplaceUser = !!localStorage.getItem('marketplace_user');
        if (savedId) {
          setPortfolio({
            ...portfolio,
            id: savedId,
            marketplaceUser: marketplaceUser
          });
          if (isMarketplaceUrl()) {
            navigate('/cpl-applicant/marketplace');
          } else if (pathname === '/cpl-applicant' || pathname === '/cpl-applicant/my-programs') {
            setTimeout(() => {
              navigate('/cpl-applicant');
              setTimeout(() => {
                navigate('/cpl-applicant/my-programs');
              }, 0)
            }, 100)
          }
        }
      }
    }
  }, [user, portfolio, showLoading]);


  if (user.isSoft || !localStorage.getItem('refresh')) {
    return <div>
      <SoftUserHeader/>
      <div style={pathname !== '/cpl-applicant' ? {padding: '24px 100px 48px'} : {}}>
        <Outlet/>
      </div>
      <Footer/>
    </div>
  }

  if (!showLoading && user.permission_groups?.includes(PermissionType.cpl_applicant)) {
    return <MenuLayout
      menuOptions={user?.permissions?.includes("is_marketplace_user")
        ? isMarketplaceUrl()
          ? CplApplicantOnlyMarketplaceMenuOptions
          : CplApplicantMarketplaceMenuOptions
        : CplApplicantMenuOptions
      }
    />
  }

  return <Loading loading/>;
}

export default CplApplicantLayout;
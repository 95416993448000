import './submit-payment.scss';
import ButtonKMQ from "../../UI/Button/ButtonKMQ";
import {useMutateStripeSession} from "../../../api/payment/useMutateStripeSession";
import {useContext, useEffect, useState} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {useNavigate} from "react-router-dom";
import Loading from "../../UI/Loading/Loading";
import {UserContext} from "../../../hooks/Context/UserContext";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import {ArrowCounterClockwise} from "@phosphor-icons/react";
import {getMarketplaceUrls} from "../../../lib/envFunctions/getMarketplaceUrls";

const PortfolioStatusButtons = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const {mutate: mutateStripeSession, isSuccess, isPending, isError, data} = useMutateStripeSession();

  const handleSubmit = () => {
    if (portfolio.id && !user.isSoft) {
      mutateStripeSession({
        portfolio_id: portfolio.id
      });
    } else {
      navigate('/login/sign-up?payment=true')
    }
  }

  const handleStartOver = () => {
    localStorage.removeItem('refresh');
    localStorage.removeItem('portfolio_id');
    localStorage.removeItem('basic_info');

    const marketplaceUrls = getMarketplaceUrls();
    if (localStorage.getItem('marketplace_user') && marketplaceUrls.length > 0) {
      localStorage.removeItem('marketplace_user');
      window.open(marketplaceUrls[0] + '/start-over');
    }

    setPortfolio({});
    navigate('/cpl-applicant');
  }

  useEffect(() => {
    if (isSuccess && data) {
      setPortfolio({
        ...portfolio,
        clientSecret: data.client_secret,
        banner_text: data?.banner_text
      })
      navigate(`/cpl-applicant/payment/${data.session_id}`)
    }
  }, [isSuccess, data, navigate])

  return <div className={'submit-payment-button'}>
    <Loading loading={isPending}/>
    <ButtonKMQ onClick={handleSubmit}>
      Submit for review
    </ButtonKMQ>
    {
      user.isSoft
        ? <ButtonKMQ onClick={handleStartOver} type={ButtonTypes.Secondary}>
          <ArrowCounterClockwise size={22} color={'#212121'} />
          Start over
        </ButtonKMQ>
        : null
    }
  </div>
}

export default PortfolioStatusButtons;
import './sorting.scss';
import {ISortingOption} from "../SearchAndSort";
import SortingIcon from '../../../../assets/icons/UI/icons/sort.svg';
import OpenIcon from '../../../../assets/icons/UI/select/arrow-down.svg';
import CloseIcon from '../../../../assets/icons/UI/select/arrow-up.svg';
import {useState} from "react";

interface SortingOptions {
  sortingOptions: ISortingOption[],
  activeSorting: ISortingOption,
  onSortingChange: (newSorting: ISortingOption) => void
}

const Sorting = (props: SortingOptions) => {
  const {
    sortingOptions,
    activeSorting,
    onSortingChange
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const handleChangeOption = (newOption: ISortingOption) => {
    setMenuIsOpen(false);
    onSortingChange(newOption);
  }

  return <div className={'sorting'}>
    <div className={'sorting-active'}>
      <img
        src={SortingIcon}
        alt={'sorting'}
        className={'sorting-active-icon'}
      />
      <span className={'sorting-active-text'}>
        Sort by: {activeSorting.label}
      </span>
      <img
        src={menuIsOpen ? CloseIcon : OpenIcon}
        alt={menuIsOpen ? 'close-menu' : 'open-menu'}
        className={'sorting-active-open-btn'}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      />
    </div>

    {
      menuIsOpen
        ? <div className={'sorting-menu'}>
          {sortingOptions.map((item: ISortingOption, index: number) =>
            <div
              className={'sorting-menu-option'}
              key={index}
              onClick={() => handleChangeOption(item)}
            >
              <div className={'sorting-menu-option-text'}>
                {item.label}
              </div>
            </div>
          )}
        </div>
        : null
    }

  </div>
}

export default Sorting;